.app--version__wrapper{
    color: #646161;
    font-family: monospace;
    text-transform: uppercase;
    font-weight: bold;
    opacity: 1.0;
    transition: opacity 350ms ease;
}

.app--version__wrapper.faint{
    opacity: 0.6;
}

.app--version__wrapper.faint:hover{
    opacity: 1.0;
}

.app--version__label{
    pointer-events: none;
    user-select: none;
}

.app--version__value{
    color: #539;
    display: block;
    font-size: 1.2em;
    cursor: pointer;
}

.app--version__value:hover::after{
    opacity: 1;
}

.app--version__value::after{
    content: '📋';
    display: inline-block;
    padding-left: 0.5em;
    font-size: 0.85em;
    transition: opacity 350ms ease;
    opacity: 0;
    filter: grayscale(1);
}
