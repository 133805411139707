body > main {
    opacity: 0.0;
}

body.loaded > main {
    opacity: 1.0;
    transition: opacity 400ms ease;
}

.fade-in{
    animation-name: fade-in;
    animation-duration: 500ms;
    animation-fill-mode: both;
}

@keyframes fade-in {
    0% {
        opacity: 0.1;
    }

    100% {
        opacity: 1;
    }
}

div.splash--modal{
    display: flex!important;
    position: fixed;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: 25%;
    animation-name: scale-rotate-fade;
    animation-duration: 7s;
    animation-fill-mode: both;
    transform-origin: center center;
    pointer-events: none;
}
div.splash--modal svg path{
    stroke: #322a;
}

div.splash--modal.corner{
    left: auto;
    top: auto;
    width: 5vw;
    height: 5vw;

    animation-name: corner-pop;
    animation-duration: 2s;
    animation-fill-mode: backwards;
    transform-origin: center center;
    pointer-events: all;
    
    background-size: 100%;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 1vw #3339;
    border: 1px solid black;
    right: 1vw;
    bottom: 1vw;
    cursor: pointer;
    transition: opacity 350ms ease;
    opacity: 0.8;

    z-index: 1000000000000;
}

div.splash--modal.corner:hover{
    opacity: 1;
}

@keyframes scale-rotate-fade {
    0% {
        transform: scale(0.1) rotate(90deg);
        opacity: 0.1;
    }
    25% {
        transform: scale(1) rotate(0deg);
        opacity: 1;
    }
    45% {
        transform: scale(1) rotate(0deg);
        opacity: 1;
    }
    100% {
        transform: scale(5.5) rotate(90deg);
        opacity: 0;
    }
}

@keyframes corner-pop {
    0% {
        transform: rotate(90deg);
        opacity: 0;
    }
    100% {
        transform: rotate(0deg);
        opacity: 0.8;
    }
}
