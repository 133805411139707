body.mode--pwa {
    position: relative;
}

body.mode--pwa div.menu--nav,
body.mode--pwa div.root--container__nav{
    top: 3em;
}

body.mode--pwa div.page--content__wrapper {
    padding-top: 3em;
}
body.mode--pwa div#installInstructions {
    display: none;
}
body.mode--pwa > div.splash--modal{
    display: none!important;
}
