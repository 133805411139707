.profile--info--label {
    font-weight: bold;
    color: #000;
}

.profile--info--label+div {
    text-align: right;
    display: flex;
    gap: 0.5em;
    justify-content: flex-end;
}

.profile--wrapper {
    margin-bottom: 0.5em;
    width: fit-content;
    padding: 1em;
    box-sizing: border-box;
}

.profile--wrapper .MuiPaper-root {
    padding: 1em 1em;

    background-color: #c7c7c7;
    color: #000;
}

body.dark .profile--wrapper .profile--info--label {
    color: #eee;
}

body.dark .profile--wrapper .chip {
    background: #eee;
    color: #111;
}

.profile--wrapper .chip {
    background: #111;
    color: #eee;
    padding: 0.2em 0.6em;
    border-radius: 0.8em;
    font-size: 0.8em;
    text-transform: uppercase;
}

img.profile--image {
    object-fit: cover;
    overflow: hidden;
}

img.profile--image.circle {
    border-radius: 16px;
}

img.profile--image.medium {
    height: 200px;
    width: 200px;
}

img.profile--image.small {
    height: 64px;
    width: 64px;
}

img.profile--image.tiny {
    height: 32px;
    width: 32px;
}

.nav--wrapper>div>div.center {
    display: flex;
    justify-content: center;
    height: 32px;
    width: 32px;
}

img.profile--image.no-image {
    background-color: #f0f;
    display: block;
}

.nav--wrapper img.profile--image {
    cursor: pointer;
}
