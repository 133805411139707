
.form--buttons{
    width: 50%;
}

.view--only .MuiOutlinedInput-root fieldset {
    border-color: transparent!important;
}

.view--only .MuiFormHelperText-root {
    display: none;
}

.rjsf details summary {
    list-style: none;
    font-weight: bold;
    font-size: 1.3em;
    color: darkred;
}

.rjsf details ul {
    padding-inline-start: 1em;
    margin-block-start: 0.5em;
}
