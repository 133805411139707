.page--wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
}

.nav--container a.active {
    color: #333;
    text-decoration: underline;
    font-weight: bold;
}


.root--container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    width: 100vw;
}

@media only screen and (max-width: 800px) {
    .root--container {
        flex-direction: column;
    }

    .root--container>.root--container__nav {
        width: auto;
        /* position: relative; */
    }

    .root--container>.root--container__outlet {
        height: auto;
        margin-left: 0;
    }

    .outlet--wrapper {
        padding: 0 1em;
    }

    .outlet--wrapper>.page--content__wrapper {
        width: 100%;
    }
}

.menu--nav {
    z-index: 20;
    position: fixed;
    right: 1em;
    top: 1em;
    display: flex;
    flex-direction: row;
    background: #ebebeb;
    padding: 0;
    border-radius: 4px;
    gap: 0.2em;
}
.menu--nav.active{
    z-index: 30000;
    background: #fff;
    border-radius: 4px 4px 0 0;
}
div#basic-menu.MuiMenu-root > .MuiPaper-root{
    border-top-right-radius: 0;
}

.root--container__nav {
    position: absolute;
    display: flex;
    min-width: fit-content;
    z-index: 10;
    left: 1em;
    top: 1em;
}

.root--container__nav>section.nav--wrapper {
    border-radius: 16px;
}

.nav--wrapper h3 {
    margin-top: 0;
}

.root--container__outlet {
    flex-grow: 2;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.root--container__outlet>section {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    height: min-content;
}

.page--content__wrapper {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    flex-grow: 1;
    padding: 1em 0;
    margin-bottom: auto;
    position: relative;
}

.page--content__wrapper.fit-vertical {
    max-height: 100vh;
}

.page--content__wrapper h1 {
    margin-top: 0;
    padding-left: 0.25em;
    margin-bottom: 0.5em;
    font-weight: normal;
}