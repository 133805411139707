.testing-manager__container {
    max-width: 1400px;
}

.testing--manager__client-container{
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    flex-flow: wrap;
}

.testing-client__container {
    display: block;
    width: 150px;
    height: 100px;
    position: relative;
    padding: 0.5em;
    border-radius: 0.25em;
    background-color: #3c3434;
    color: #eee;
    overflow: hidden;
}

.testing-manager__container .form--buttons {
    width: 100%;
}

.testing-client__container .is-host{
    background-color: #011;
}
.testing-client__room-state > div > div:first-child {
    font-weight: bold;
}

.testing-client__room-state > div {
    display: flex;
    gap: 0.25em;
    font-size: 0.8em;
}

.testing-client__container::before {
    display: block;
    content: '';
    width: 1em;
    height: 1em;
    position: absolute;
    right: -0.5em;
    transform: rotate(45deg);
    bottom: -0.5em;
    background-color: red;
    z-index: 1000;
}

.testing-client__container.active::before {
    background-color: green;
}

.testing-client__role{
    position: absolute;
    bottom: 0.5em;
    left: 0.5em;
    text-transform: uppercase;
    font-weight: lighter;
}
