.video--wrapper__render {
    flex-grow: 1;
    display: flex;
}

.video--wrapper__controls {
    /* justify-content: space-between; */
    display: flex;
    z-index: 2000;
    position: absolute;
    right: 1em;
    top: 5em;
    z-index: 10000;
    left: 0;
    padding: 0 1em;
    justify-content: flex-end;
    box-sizing: border-box;
}

.vid.self {
    flex-grow: 1;
}
.vid.self > div {
    background-color: transparent!important;
}
.vid.self > div > video {
  animation-name: fade-in;
  animation-duration: 500ms;
}
.vid.remote {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
    top: 2em;
    left: 1em;
    box-shadow: 0 0 1em #111a;
    border: 1px solid #7eb40c;
}

.video--wrapper {
    flex-grow: 2;
    display: flex;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.video--remote__container {
    position: absolute;
    padding: 1em;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}

.video--remote__container.right {
    flex-direction: row-reverse;
}

.video--remote__container.vertical {
    width: min-content;
    flex-direction: column;
}

.video--remote__container.vertical.bottom {
    flex-direction: column-reverse;
}
.video--local__container{
    flex-grow: 1;
    display: flex;
}

.video--wrapper > .error {
    margin: 1em;
    align-self: flex-end;
    color: darkred;
}
.video--wrapper > .error > h3 {
    margin: 0;
}
