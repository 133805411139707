div.scroll--end {
    position: absolute;
    bottom: 0em;
    left: 0;
    right: 0;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;

    animation-name: fade-in;
    animation-duration: 500ms;
    animation-fill-mode: both;
}

div.scroll--end button {
    box-shadow: 0 0 4px #3335;
    cursor: pointer;
    background-color: lightyellow;
    border: 1px solid #666;
    border-radius: 4px;
    color: #444;
    font-size: 0.8em;
    padding: 0.2em 0.4em;
    opacity: 0.8;
}

div.chat--wrapper {
    flex: 1 1 auto;
    background-color: #e5e5e5;
    border: 1px solid #ababab;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
}

.chat--wrapper__message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: wrap;
    gap: 0;
}
.chat--wrapper__message > .data-message > p{
 display: inline;
}

.chat--wrapper__message>span {
    font-size: 0.45em;
    line-height: 1em;
    height: 1em;
}

.chat--wrapper__message .label-user {
    font-weight: bold;
    margin-left: 1rem;
    width: 50%;
}

.chat--wrapper__message .data-message {
    flex-grow: 1;
    color: #333;
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
}

.chat--wrapper__message .label-timestamp {
    color: #999;
    margin-right: 1rem;
    font-size: 0.35em;
}

.zoom--icon {
    cursor: pointer;
    transform-origin: center center;
    transition: 350ms ease transform, 350ms ease width;
}

.zoom--icon:hover {
    width: 1.2em;
    transform: scale(1.5);
    transition: 350ms ease transform, 350ms ease width;
}


@keyframes fade-in {
    0% {
        opacity: 0.1;
    }

    100% {
        opacity: 1;
    }
}

.dark div.chat--wrapper {
    background-color: #141414;
    border: 1px solid #bbb;
}

.dark .chat--wrapper__message .label-timestamp,
.dark .chat--wrapper__message .label-user,
.dark .data-message {
    color: #eee;
}

.swap-side.chat--wrapper__message .label-user {
    margin-right: 1rem;
    margin-left: 0;
    text-align: right;
}

.swap-side.chat--wrapper__message .label-timestamp {
    margin-left: 1rem;
    margin-right: 0;
}

.swap-side.chat--wrapper__message {
    flex-direction: row-reverse;
}

.chat--wrapper::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 30%;
    background: linear-gradient(#e5e5e5, #e5e5e500);
    content: '';
    z-index: 1000;
    pointer-events: none;
}

.panel--overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.panel--overlay .MuiSvgIcon-root {
    font-size: 10rem;
}

.fullscreen-button {
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    z-index: 10001;
    cursor: pointer;
}

.fullscreen-button > .is-fullscreen,
.fullscreen-button > .not-fullscreen {
    position: absolute;
    right: 0;
    top: 0;
    width: 1.25em;
    height: 1.25em;
}

:fullscreen .fullscreen-button > .is-fullscreen,
.fullscreen-button > .not-fullscreen {
    opacity: 1;
    pointer-events: all;
    transition: 1s opacity ease;
}

:fullscreen .fullscreen-button > .not-fullscreen,
.fullscreen-button > .is-fullscreen {
    opacity: 0;
    pointer-events: none;
    transition: 1s opacity ease;
}

.chat--wrapper .chat--wrapper__header {
    position: absolute;
    left: 0.6em;
    top: 0.7em;
    z-index: 10005;
}
