.MuiDataGrid-columnHeaderTitle{
    --unstable_DataGrid-headWeight: bold;
}

.center > .MuiAvatar-root.MuiAvatar-circular{
    border: 2px solid #ffffffcf;
    box-shadow: inset 0px 0px 3px 0px #00000052;
}

.center > .MuiAvatar-root.MuiAvatar-circular > img.MuiAvatar-img {
    mix-blend-mode: overlay;
}
