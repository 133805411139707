body {
    background: #ddd;
    padding: 0;
    margin: 0;
    font-family: sans-serif;
    text-rendering: geometricPrecision;
}

body.dark {
    background-color: #141414;
    color: #eee;
}

.app--title {
    font-size: 1.5em;
    margin-bottom: 0.5em;
}

li[role=menuitem]>a.active {
    font-weight: bold;
    color: #505050 !important;
}

.nav--container>a {
    margin-right: 0.25em;
    border-right: 2px solid #666;
    padding-right: 0.25em;
    display: inline-block;
    vertical-align: text-top;
    line-height: 0.8em;
    color: #000;
    text-decoration: none;
}

.nav--container>a:last-child {
    border-right: none;
    padding-right: 0;
    margin-right: 0;
}

li[role=menuitem]>a {
    display: block;
    width: 100%;
    text-decoration: none;
    color: inherit;
}

li[role=menuitem]>p {
    display: block;
    padding: 6px 16px;
    font-size: 0.8em;
    min-width: 125px;
}

.menu--nav>button {
    min-width: 32px;
    padding: 0;
}

.menu--nav>button>svg {
    padding: 0.25em 0.4em;
}

div.loader--wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
}

div.error--handler {
    padding: 0 1em;
}

li[role=menuitem] {
    padding: 0;
}

li[role=menuitem]>a {
    padding: 6px 16px;
}

#installInstructions {
    display: none
}

@media (display-mode: browser) {
    #installInstructions {
        display: block
    }
}

div#installInstructions {
    position: fixed;
    bottom: 1em;
    left: 1em;
    text-decoration: underline;
    color: #777;
}
