div.menu--nav {
    view-transition-name: main-nav;
}
div.root--container__nav {
    view-transition-name: main-profile;
}
main#root > div#installInstructions {
    view-transition-name: main-instructions;
}

::view-transition-old(root) {
    animation: slide-to-left 0.5s ease-in-out 1 forwards;
    background-color: #f0f;
}

::view-transition-new(root) {
    animation: slide-from-right 1s ease-in-out 1 forwards;
}

@keyframes fade-and-scale-in {
    from {
        opacity: 0;
        transform: scale(0);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fade-and-scale-out {
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        transform: scale(2);
    }
}

/* @keyframes fade-in {
    from { opacity: 0; }
  }
  
  @keyframes fade-out {
    to { opacity: 0; }
  }
  */
  @keyframes slide-from-right {
    from { transform: translateX(50vw); opacity: 0; }
    to { transform: translateX(0vw); opacity: 1; }
  }
  
  @keyframes slide-to-left {
    from { opacity: 1; transform: translateX(0vw); }
    to { transform: translateX(-50vw); opacity: 0; }
  }
  /*
  ::view-transition-old(root) {
    animation: 300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left;
  }
  
  ::view-transition-new(root) {
    animation: 300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right;
  } */